import React, { useEffect, useState } from 'react'
import footerLogo from "../footer-logo.svg"
import axios from 'axios'
import '../style.scss'

const Footer = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    const url = 'https://hydros1.wpengine.com/wp-json/rae/v1/header-footer?header_location_id=hcms-menu-header&footer_location_id=hcms-menu-footer'
    axios.get(url).then((res) => {
      setData(res.data.data.footer)
    })
  }, [])


  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="145.522" class="hideme"><symbol id="footer-logo" viewBox="0 0 112 145.522"><title>footerlogo</title><defs><clipPath id="clip-path"><rect id="Rectangle_17" data-name="Rectangle 17" width="112" height="145.522" fill="none"/></clipPath></defs><g id="Group_128" data-name="Group 128" transform="translate(-235.237 -2209.354)"><g id="Group_133" data-name="Group 133" transform="translate(235.237 2209.354)"><g id="Group_20" data-name="Group 20" transform="translate(0 0)"><g id="Group_19" data-name="Group 19" clip-path="url(#clip-path)"><path id="Path_34" data-name="Path 34" d="M46.552,91.714c0-4.291,1.937-9.906,7.945-11.623A39.5,39.5,0,0,0,74.88,14.435c-8.8-9.224-2.36,10.945-12.874,23.174S26.175,45.333,16.949,44.9c-9.441-.431-9.441,5.149-7.939,9.655A38.749,38.749,0,0,0,38.4,80.092c6.008,1.716,8.147,7.331,8.147,11.623" transform="translate(9.481 13.853)"/><path id="Path_35" data-name="Path 35" d="M31.955,50.724c0,4.291-.217,4.291-2.575,9.655-2.362,5.147-8.37,9.655-8.37,15.875A11.22,11.22,0,0,0,32.171,87.414,11.081,11.081,0,0,0,43.326,76.254c0-6.22-6.224-10.728-8.582-15.875-2.573-5.364-2.789-5.364-2.789-9.655" transform="translate(24.069 58.109)"/><path id="Path_36" data-name="Path 36" d="M56,0A55.979,55.979,0,0,0,45.272,110.926c4.72.86,6.653-9.438,1.931-10.3a45.486,45.486,0,1,1,17.6,0c-4.722.86-2.789,11.159,1.929,10.3A55.979,55.979,0,0,0,56,0" transform="translate(0 0.002)"/></g></g></g></g></symbol></svg>
      <footer class="pad_top_100 ">
        <div class="container">
          <div class="footer-menu">
            <div class="first-col quarter" dangerouslySetInnerHTML={{ __html: data.sidebarOne }} />
            <div class="second-col quarter" dangerouslySetInnerHTML={{ __html: data.sidebarTwo }} />
            <div class="third-col quarter" dangerouslySetInnerHTML={{ __html: data.sidebarThree }} />
            <div class="forth-col quarter" dangerouslySetInnerHTML={{ __html: data.sidebarFour }} />
            <div class="fifth-col quarter" dangerouslySetInnerHTML={{ __html: data.sidebarFive }} />
            <div class="sixth-col quarter" dangerouslySetInnerHTML={{ __html: data.sidebarSix }} />
          </div>

          {/* <!-- Copyright --> */}
          <div class="site-info">
            <div class="copyright-content">
              <p>© hydros clinics</p>
              <p><a class="external" href="https://owdt.com/" target="_blank" rel="noopener">Web Design</a> BY <a class="external" href="https://owdt.com/" target="_blank" rel="noopener">OWDT</a></p>
            </div>
          </div>
          {/* <div className=' main-content container '>
          <h1>{data2.hero_heading}</h1>
          </div> */}
        </div>
      </footer>
    </>
  )
}

export default Footer

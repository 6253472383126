import React, { useEffect, useState } from 'react'
import axios from 'axios'
import headerLogo from "../header-logo.svg"
import '../style.scss'
import { Link } from 'react-router-dom'

const Header = () => {
  const [data, setData] = useState([])
  const [item, setItem] = useState([])
  useEffect(()=>{
      const url = 'https://hydros1.wpengine.com/wp-json/rae/v1/header-footer?header_location_id=hcms-menu-header&footer_location_id=hcms-menu-footer'
      axios.get(url).then((res)=>{  
        setData(res.data.data.header.headerMenuItems)
      })
    },[])
  return (
    <>
      <div class="container">
        <header id="masthead" >
          <div class="site-branding">
            {/* <a href="/" rel="home">
              <img src={headerLogo} />
            </a> */}
            <Link to='/'>
            <img src={headerLogo} />

            </Link>
          </div>

          <div class="header-dropdown">
            <span>I need help with</span>
            <div class="select-dropdown">
              <button href="#" role="button" data-value="" class="select-dropdown__button"><span>select one</span>
              </button>
              <ul class="select-dropdown__list">
                <li data-value="canada" class="select-dropdown__list-item">Health Optimization</li>
                <li data-value="caribbean-islands" class="select-dropdown__list-item">Athletic Recovery</li>
                <li data-value="pacific-islands" class="select-dropdown__list-item">Mold Exposure</li>
              </ul>
            </div>
          </div>

          <div class="hamburger"><span></span></div>
            <div class="main-navigation">
              <div class="menu-main-menu-container">
                <ul id="menu-main-menu" class="menu">
                  {data.length  ? data.map((item,i)=>(
                    // <li><a href={item.pageSlug}>{item.title}</a></li>
                    <li><Link
                    to={`${item.pageSlug}/`}>{item.title}
                    </Link></li>

                  )):null }
              </ul>
            </div>      
          </div>
        </header>
      </div>

    </>
  )
}

export default Header

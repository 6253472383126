import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../style.scss'
import LoaderComponent from './LoaderComponent';
import { motion as m} from 'framer-motion'

const About = () => {
    const [data,setData] = useState("")
    const [load,setLoad] = useState(true)


    useEffect(() => {
        const url = 'https://hydros1.wpengine.com/wp-json/wp/v2/pages/13'
        axios.get(url).then((res) => {
            if (res.data && res.data.content && res.data.content.rendered)
                setData(res.data.content.rendered)
        })
    }, [])

    useEffect(()=>{
        setTimeout(() => {
            setLoad(false)
            console.log("loding");
        }, 1000);
    },[])


    return (
        <>
        {/* {load ? 
        <div   class="main-wrapper">
            <LoaderComponent />
        </div>
            : */}
            <m.div
            // initial={{opacity:0}}
            // animate={{opacity:1}}
            // transition={{duration: 1.75, ease :'easeOut'}}
            initial={{width:0}}
            animate={{width:"100%"}}
            exit={{x:window.innerWidth, transition : {duration : 0.1}}}
            >
            <div class="main-wrapper" dangerouslySetInnerHTML={{ __html: data }} />
            </m.div>
        {/* } */}
        </>
    )
}

export default About
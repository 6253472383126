import './App.css';
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import Footer from './layout/Footer';
import Header from './layout/Header';
import About from './pages/About';
import Home from './pages/Home';
import WhyTherapy from './pages/WhyTherapy';
import Treatments from './pages/Treatments';
import Contact from './pages/Contact';
import LoaderComponent from './pages/LoaderComponent';

function App() {  

  return (
    <>
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about/" element={<About />} />
          <Route path="/why-therapy/" element={<WhyTherapy />} />
          <Route path="/treatments/" element={<Treatments />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
        <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;

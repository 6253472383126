import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../style.scss'

const LoaderComponent = () => {
    return (
        <>
        <div class="loader-container">
            <div class="spinner"></div>
        </div>
        </>
    )
}

export default LoaderComponent